@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.animatedText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .text {
    font-style: normal;
    font-weight: bold;
    font-size: 96px;
    line-height: 100%;
    letter-spacing: -0.04em;
    background: linear-gradient(180deg, #6f6ca6 0%, #499ebd 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    filter: drop-shadow(0 5px 0.2rem #4d4d4d27);
    @media only screen and (max-width: 600px) {
      font-size: 3rem;
    }
  }
  .floatingGummie {
    position: absolute;
    width: 100px;
    object-fit: contain;
    @media only screen and (max-width: 600px) {
      width: 50px;
    }
    &:nth-child(1) {
      left: 50px;
      top: 0px;
    }
    &:nth-child(2) {
      left: 170px;
      top: 170px;
    }
    &:nth-child(3) {
      right: 150px;
      top: 50px;
    }
    &:nth-child(4) {
      right: 70px;
      top: 200px;
    }
    &:nth-child(5) {
      right: 210px;
      top: 400px;
    }
    &:nth-child(6) {
      left: 80px;
      top: 300px;
    }
    &:nth-child(7) {
      right: 20px;
      top: 250px;
      z-index: -1;
      width: 100px;
    }
  }
  .floatingGar,
  .floatingGar2 {
    position: absolute;
  }
  .floatingGar {
    transform: translate(40vw, 40px);
    object-fit: contain;
    width: 40vw;
    filter: drop-shadow(0 10px 0.3rem #4d4d4d52);
    @media only screen and (max-width: 600px) {
      width: 30vw;
    }
  }
  .floatingGar2 {
    transform: translate(0px, -80px);
    object-fit: contain;
    width: 350px;
    filter: drop-shadow(0 10px 0.3rem #4d4d4d52);
    @media only screen and (max-width: 600px) {
      width: 30vw;
    }
  }
}

.shimmer-line {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 10%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  display: inline-block;
  position: relative;
  animation: shimmer 1.5s infinite linear;
  border-radius: 5px;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.custom-swal {
  z-index: 2050; /* Adjust this value as needed */
}
.swal2-container {
  z-index: 50000 !important; /* Apply the z-index */
}
